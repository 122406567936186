// Generated by Framer (ff86393)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Y3dG16jMA", "uyY0js87B"];

const serializationHash = "framer-Hes8s"

const variantClassNames = {uyY0js87B: "framer-v-1m9x019", Y3dG16jMA: "framer-v-1ywxk9s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Big: "Y3dG16jMA", Small: "uyY0js87B"}

const getProps = ({height, id, radius, screenImage, width, ...props}) => { return {...props, JVmXBU6uC: radius ?? props.JVmXBU6uC ?? "32px 32px 32px 32px", MqFxfGEuE: screenImage ?? props.MqFxfGEuE ?? {src: "https://framerusercontent.com/images/nad6PGuH1PYtkKCukQFRlhEbheA.jpeg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/nad6PGuH1PYtkKCukQFRlhEbheA.jpeg?scale-down-to=2048 944w,https://framerusercontent.com/images/nad6PGuH1PYtkKCukQFRlhEbheA.jpeg 1179w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Y3dG16jMA"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;screenImage?: {src: string; srcSet?: string};radius?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MqFxfGEuE, JVmXBU6uC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Y3dG16jMA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "Mobile Image", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1080, pixelWidth: 546, positionX: "center", positionY: "top", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/I9yRU9jEN2S2ol2M7kgT5c6zOrc.png", srcSet: "https://framerusercontent.com/images/I9yRU9jEN2S2ol2M7kgT5c6zOrc.png?scale-down-to=1024 517w,https://framerusercontent.com/images/I9yRU9jEN2S2ol2M7kgT5c6zOrc.png 546w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ywxk9s", className, classNames)} data-framer-name={"Big"} layoutDependency={layoutDependency} layoutId={"Y3dG16jMA"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({uyY0js87B: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 10)), pixelHeight: 1688, pixelWidth: 780, sizes: `calc(${componentViewport?.width || "100vw"} - 28px)`, ...toResponsiveImage(MqFxfGEuE), ...{ positionX: "center", positionY: "top" }}} className={"framer-mp9r6q"} layoutDependency={layoutDependency} layoutId={"A2yoM55Zi"} style={{borderBottomLeftRadius: radiusForCorner(JVmXBU6uC, 3), borderBottomRightRadius: radiusForCorner(JVmXBU6uC, 2), borderTopLeftRadius: radiusForCorner(JVmXBU6uC, 0), borderTopRightRadius: radiusForCorner(JVmXBU6uC, 1)}} {...addPropertyOverrides({uyY0js87B: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 8)), pixelHeight: 1688, pixelWidth: 780, sizes: `calc(${componentViewport?.width || "100vw"} - 20px)`, ...toResponsiveImage(MqFxfGEuE), ...{ positionX: "center", positionY: "top" }}}}, baseVariant, gestureVariant)}/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hes8s.framer-12cp4ls, .framer-Hes8s .framer-12cp4ls { display: block; }", ".framer-Hes8s.framer-1ywxk9s { height: 514px; overflow: hidden; position: relative; width: 260px; }", ".framer-Hes8s .framer-mp9r6q { bottom: 10px; flex: none; left: 14px; overflow: hidden; position: absolute; right: 14px; top: 10px; will-change: var(--framer-will-change-override, transform); }", ".framer-Hes8s.framer-v-1m9x019.framer-1ywxk9s { aspect-ratio: 0.5055555555555555 / 1; height: var(--framer-aspect-ratio-supported, 515px); }", ".framer-Hes8s.framer-v-1m9x019 .framer-mp9r6q { bottom: 8px; left: 10px; right: 10px; top: 8px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 514.5
 * @framerIntrinsicWidth 260
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uyY0js87B":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MqFxfGEuE":"screenImage","JVmXBU6uC":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXmuveLieS: React.ComponentType<Props> = withCSS(Component, css, "framer-Hes8s") as typeof Component;
export default FramerXmuveLieS;

FramerXmuveLieS.displayName = "Helper/ Mobile UI";

FramerXmuveLieS.defaultProps = {height: 514.5, width: 260};

addPropertyControls(FramerXmuveLieS, {variant: {options: ["Y3dG16jMA", "uyY0js87B"], optionTitles: ["Big", "Small"], title: "Variant", type: ControlType.Enum}, MqFxfGEuE: {__defaultAssetReference: "data:framer/asset-reference,nad6PGuH1PYtkKCukQFRlhEbheA.jpeg?originalFilename=IMG_B347188C3A0D-1.jpeg&preferredSize=auto", title: "Screen Image", type: ControlType.ResponsiveImage}, JVmXBU6uC: {defaultValue: "32px 32px 32px 32px", title: "Radius", type: ControlType.BorderRadius}} as any)

addFonts(FramerXmuveLieS, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})